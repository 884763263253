import React, { useRef } from "react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  TextField,
  Alert,
  Container,
  Link,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
// hooks
import Page from "../components/Page";
import useIsMountedRef from "./../utils/useIsMountedRef";
import firebase from "./../firebase";

// ----------------------------------------------------------------------

ResetPasswordForm.propTypes = {
  onSent: PropTypes.func,
  onGetEmail: PropTypes.func,
};

export default function ResetPasswordForm({ onSent, onGetEmail }) {
  const isMountedRef = useIsMountedRef();

  // Ref for the email input field
  const emailRef = useRef(null);

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
  });

  const resetPassword = async (email) => {
    await firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        alert(`Reset link sent to ${email}`);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});
    setAfterSubmit(null);

    // Get email from ref
    const email = emailRef.current.value;

    try {
      // Validate form using Yup schema
      await ResetPasswordSchema.validate({ email }, { abortEarly: false });
      await resetPassword(email);
      setSubmitting(false);
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors({ email: err.errors[0] });
      } else {
        setAfterSubmit(err.message);
      }
      setSubmitting(false);
    }
  };

  const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  }));

  const ContentStyle = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(12, 0),
  }));

  const [errors, setErrors] = React.useState({});
  const [afterSubmit, setAfterSubmit] = React.useState(null);
  const [isSubmitting, setSubmitting] = React.useState(false);

  return (
    <RootStyle title="Reset Password">
      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Forgot Password
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Reset link may be found in the spam folder.
            </Typography>
          </Box>

          <form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {afterSubmit && <Alert severity="error">{afterSubmit}</Alert>}

              <TextField
                fullWidth
                label="Email address"
                inputRef={emailRef} // Use ref for uncontrolled component
                error={Boolean(errors.email)}
                helperText={errors.email}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Reset Password
              </LoadingButton>
              <Link
                component={RouterLink}
                variant="subtitle2"
                to="/login"
                underline="hover"
              >
                Admin Login
              </Link>
            </Stack>
          </form>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
