import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// material
import {
  Grid,
  Button,
  Container,
  Stack,
  Typography,
  Card,
  TextField,
  MenuItem,
  Divider,
  LinearProgress,
} from "@mui/material";
// components
import Page from "src/components/Page";
import firebase from "src/firebase";
import emailjs from "@emailjs/browser";
import { fNumber } from "src/utils/formatNumber";
import useGetQuote from "src/hooks/useGetQuote";
import useGetQuoteLogs from "src/hooks/useGetQuoteLogs";
import UserView from "./UserView";
import QuoteView from "./QuoteView";

import { PDFViewer } from "@react-pdf/renderer";

// import  DialogAnimate  from '../../components/DialogAnimate';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import InvoiceForm from "./InvoiceForm";
import { MainLoadingPage } from "src/components/customs/MainLoadingPage";
export default function ExternalQuote({ disable }) {
  // get the project id from the url
  const location = useLocation();
  const navigate = useNavigate();
  const quoteId = location.pathname.split("/")[2];
  const [loading, setLoading] = useState(false);

  // if (!quoteId) {
  //   navigate("/404");
  // }

  const data = useGetQuote(quoteId).docs;

  let publicKey = "54lQzVPslTf0AlGlL";

  const [values, setValues] = useState(null);

  const [status, setstatus] = useState(null);

  const notify = (msg) => toast(msg);

  const updateStatus = () => {
    firebase
      .firestore()
      .collection("quotes")
      .doc(location.state.data)
      .update({ status: status })
      .then(() => {
        firebase
          .firestore()
          .collection("logs")
          .add({
            user_id: firebase.auth().currentUser.uid,
            log: status,
            item_id: location.state.data,
            task: "QUOTE_UPDATE",
            createdAt: new Date(Date.now()).toLocaleString(),
          })
          .then(() => {
            notify("Quote updated");

            setstatus(null);
          });
      });
  };

  const attachQuoteToUser = () => {
    firebase
      .firestore()
      .collection("quotes")
      .doc(location.state.data)
      .update({
        assigned_to: firebase.auth().currentUser.uid,
        status: " ASSIGNED",
      })
      .then(() => {
        firebase
          .firestore()
          .collection("logs")
          .add({
            user_id: firebase.auth().currentUser.uid,
            log: "ASSIGNED",
            item_id: location.state.data,
            task: "QUOTE_ASSIGNED",
            createdAt: new Date(Date.now()),
          })
          .then(() => {
            notify("Quote updated");
          });
      });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const deleteItem = () => {
    firebase
      .firestore()
      .collection("quotes")
      .doc(location.state.data)
      .delete()
      .then(() => {
        alert("Quote was deleted successfully");
        navigate(-1);
      })
      .catch((e) => {
        alert(e);
      });
  };

  console.log(data, "DATA");
  return (
    <Page title="Accept invoice">
      {loading && <LinearProgress />}
      {data && (
        <Container maxWidth="xxl">
          {/* {data.status === "COMPLETED" ? null : (
            <Button
              color="error"
              size="medium"
              onClick={() => deleteItem()}
              variant="contained"
            >
              Delete Quote
            </Button>
          )} */}
          <Stack></Stack>
          <Stack
            direction="row"
            spacing={1}
            alignItems="flex-start"
            justifyContent="space-between"
            mb={5}
          >
            {/* {data.status === "COMPLETED" ? null :
     data.assigned_to ? 
     <Typography>
     <UserView data={data.assigned_to} /> is assigned to this quote
     </Typography>
     : 
        <Button
          variant="contained"
          onClick={()=>attachQuoteToUser()}
             >
         WORKING ON IT 
        </Button>} */}
          </Stack>

          <Grid container maxWidth spacing={1}>
            <Grid item xs={12} sm={12} md={3}>
              <Stack spacing={3}>
                {/* {data.status !== "COMPLETED" && (
                  <Card sx={{ p: 3 }}>
                    <Typography variant="body2" gutterBottom>
                      Update Status
                    </Typography>

                    <Stack>
                      {status && (
                        <Typography variant="body2" gutterBottom>
                          Are you sure you want to update the status of this
                          Quote?
                        </Typography>
                      )}
                      {!status && (
                        <>
                          {data.status === "NEW" && (
                            <Button
                              onClick={() => setstatus("WORKING_ON_IT")}
                              variant={
                                status === "WORKING_ON_IT"
                                  ? "contained"
                                  : "outlined"
                              }
                            >
                              WORKING ON IT
                            </Button>
                          )}
                          {data.status === "WORKING_ON_IT" && (
                            <Button
                              onClick={() => setstatus("IN_PROGRESS")}
                              variant={
                                status === "IN_PROGRESS"
                                  ? "contained"
                                  : "outlined"
                              }
                            >
                              IN PROGRESS
                            </Button>
                          )}
                          {data.status === "IN_PROGRESS" && (
                            <Button
                              style={{
                                marginBottom: 20,
                              }}
                              onClick={() => setstatus("CLOSED")}
                              variant={
                                status === "CLOSED" ? "contained" : "outlined"
                              }
                            >
                              CLOSED
                            </Button>
                          )}
                          {data.status === "IN_PROGRESS" && (
                            <Button
                              onClick={() => setstatus("INVOICE")}
                              variant={
                                status === "INVOICE" ? "contained" : "outlined"
                              }
                            >
                              INVOICE
                            </Button>
                          )}
                          {data.status === "INVOICE" && (
                            <Button
                              onClick={() => setstatus("COMPLETED")}
                              variant={
                                status === "COMPLETED"
                                  ? "contained"
                                  : "outlined"
                              }
                            >
                              PAID
                            </Button>
                          )}
                        </>
                      )}
                      <br />
                      <br />

                      {status && (
                        <Button
                          variant="contained"
                          onClick={() => updateStatus()}
                        >
                          Update
                        </Button>
                      )}
                    </Stack>
                  </Card>
                )} */}

                <Card sx={{ p: 3 }}>
                  <Stack spacing={1}>
                    {/* <Typography variant="body2" gutterBottom>
                      Status
                      <Typography variant="h6" gutterBottom>
                        {data.status === "NEW" && "NEW"}
                        {data.status === "WORKING_ON_IT" && "WORKING ON IT"}
                        {data.status === "IN_PROGRESS" && "IN PROGRESS"}
                        {data.status === "CLOSED" && "INVOICED"}
                        {data.status === "INVOICE" && "INVOICED"}
                        {data.status === "COMPLETED" && "PAID"}
                      </Typography>
                    </Typography> */}
                    <Stack
                      spacing={3}
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography variant="body2" gutterBottom>
                        Date
                        <Typography variant="h6" gutterBottom>
                          {data.createdAt}
                        </Typography>
                      </Typography>
                    </Stack>
                    <Typography variant="body2" gutterBottom>
                      Client Name
                      <Typography variant="h6" gutterBottom>
                        {data.client}
                      </Typography>
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                      Project Name
                      <Typography variant="h6" gutterBottom>
                        {data.project}
                      </Typography>
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                      Input
                      <Typography variant="h6" gutterBottom>
                        {data.type === "Office" || data.type === "Warehouse"
                          ? `${data.gfa}m² Gross Floor Area`
                          : `${data.typology} Typology, ${data.units} Units`}
                      </Typography>
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                      Email
                      <Link
                        to={"/clients/"}
                        state={{ data: data.email }}
                        style={{
                          display: "grid",
                          color: "inherit",
                          textDecoration: "underline",
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          {data.email}
                        </Typography>
                      </Link>
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                      Performance Modeling
                      <Typography variant="h6" gutterBottom>
                        {data.Performance_Modeling_Simulation
                          ? "Enabled"
                          : "Off"}
                      </Typography>
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Building Type
                      <Typography variant="h6" gutterBottom>
                        {data.type}
                      </Typography>
                    </Typography>
                  </Stack>
                </Card>

                <Card sx={{ p: 3 }}>
                  <Typography variant="body2" gutterBottom>
                    Download PDF Quote
                  </Typography>
                  {data.quoteFile ? (
                    <Button color="info" size="medium" variant="contained">
                      <a
                        href={data.quoteFile}
                        rel="noreferrer"
                        target="_Blank"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        Download Quote
                      </a>
                    </Button>
                  ) : (
                    <Button color="info" size="medium" variant="contained">
                      <a
                        href={data.quoteFile}
                        rel="noreferrer"
                        target="_Blank"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        Download Quote
                      </a>
                    </Button>
                  )}
                  <Stack></Stack>
                </Card>

                {/* <Card sx={{p:3}}>
<Typography variant="body2" gutterBottom>
            Attach Documents            
        </Typography> 
        {data.quote_file && <UploadFile />}
    <Stack></Stack></Card> */}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Stack spacing={3}>
                <Card sx={{ p: 3 }}>
                  <Stack
                    spacing={3}
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography variant="h6" gutterBottom>
                      Expert Services
                      <br />
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      R {fNumber(data.ExpertServices)}
                    </Typography>
                  </Stack>
                  <Typography variant="body1" gutterBottom>
                    Documentation Preliminary Certification
                    <br />
                    <Typography variant="h6" gutterBottom>
                      R {fNumber(data.DPC)}
                    </Typography>
                  </Typography>
                  {data.type === "Residential" ? (
                    <Typography variant="body1" gutterBottom>
                      Initial Assessment
                      <br />
                      <Typography variant="h6" gutterBottom>
                        R {fNumber(data.IA)}
                      </Typography>
                    </Typography>
                  ) : (
                    <Typography variant="body1" gutterBottom>
                      Sustainability Consulting
                      <br />
                      <Typography variant="h6" gutterBottom>
                        R {fNumber(data.SC)}
                      </Typography>
                    </Typography>
                  )}

                  {data.type === "Residential" ? (
                    <Typography variant="body1" gutterBottom>
                      Documentation Post Construction Certification
                      <br />
                      <Typography variant="h6" gutterBottom>
                        R {fNumber(data.DCPCC)}
                      </Typography>
                    </Typography>
                  ) : (
                    <Typography variant="body1" gutterBottom>
                      Documentation Post Construction Certification
                      <br />
                      <Typography variant="h6" gutterBottom>
                        R {fNumber(data.DPCC)}
                      </Typography>
                    </Typography>
                  )}
                </Card>

                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="h6" gutterBottom>
                        Auditor Services
                        <br />
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        R {fNumber(data.AuditorServices)}
                      </Typography>
                    </Stack>

                    {data.type === "Residential" ? (
                      <Typography variant="body1" gutterBottom>
                        Preliminary Audit
                        <br />
                        <Typography variant="h6" gutterBottom>
                          R {fNumber(data.PA)}
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography variant="body1" gutterBottom>
                        Post Construction Site Audit
                        <br />
                        <Typography variant="h6" gutterBottom>
                          R {fNumber(data.PCSA)}
                        </Typography>
                      </Typography>
                    )}

                    {data.type === "Residential" ? (
                      <Typography variant="body1" gutterBottom>
                        Post Construction Audit
                        <br />
                        <Typography variant="h6" gutterBottom>
                          R {fNumber(data.PCA)}
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography variant="body1" gutterBottom>
                        Preliminary Design Audit
                        <br />
                        <Typography variant="h6" gutterBottom>
                          R {fNumber(data.PDA)}
                        </Typography>
                      </Typography>
                    )}
                  </Stack>
                </Card>

                {data.PMS_Enabled && (
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                      <Typography variant="body1" gutterBottom>
                        Performance Modeling Simulation
                        <Typography variant="h6" gutterBottom>
                          R {fNumber(data.PMS)}
                        </Typography>
                      </Typography>
                    </Stack>
                  </Card>
                )}

                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="h6" gutterBottom>
                        Total Consulting Fees
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        R {fNumber(data.TotalFees)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Card>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={5}>
              <Stack spacing={1}>
                <Card sx={{ p: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Invoice details
                  </Typography>
                  <Stack spacing={3}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="body1" gutterBottom>
                        Enter your invoice details to accept the quote
                      </Typography>
                    </Stack>
                  </Stack>
                  <InvoiceForm
                    disable={disable}
                    indicateLoading={setLoading}
                    quoteId={quoteId}
                  />
                </Card>
              </Stack>
              <br />
              {/* [TODO:] UI Refactors */}
              {/* {data?.projectInitiated === true ? (
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  component={Link}
                  style={{ marginBottom: 10, width: "100%" }}
                  to={`/projects/view/${data?.projectId}`}
                >
                  View project
                </Button>
              ) : (
                data?.status?.toLowerCase() === "completed" && (
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    style={{ marginBottom: 10, width: "100%" }}
                    onClick={() => {
                      // console.log("Initiate project", data.id);
                      navigate(`/projects/initiate/${data.id}`);
                    }}
                  >
                    Initiate project
                  </Button>
                )
              )} */}
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
}
